<template>
  <Dialog
    v-model:visible="isOpen"
    :closable="false"
    :closeOnEscape="true"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>7) ประเมินสุขภาพและความสามารถในการใช้ร่างกาย</h3>
    </template>

    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">1) มีอาการปวดคอมากจนร้าวลงแขน</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios1"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice1']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">2) มีอาการปวดหลังมากจนร้าวลงขา</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios2"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice2']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >3)
        มีปัญหากล้ามเนื้อหรือเส้นเอ็นที่แขนหรือขาที่ทำให้เกิดอาการปวดบ่อย</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios3"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice3']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >4) มีปัญหาข้อต่อที่ทำให้มีอาการปวดหรือเคลื่อนไหวได้ไม่เต็มที่ เช่น
        ข้อไหล่ติด ข้อเข่าเสื่อม ข้อต่อผิดรูป ยกแขนได้ไม่สุดแขน ขาโก่ง
        เป็นต้น</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios4"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice4']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">5) สามารถนั่งนานเกิน 30 นาที</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios5"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice5']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">6) สามารถยืน-เดินนานเกิน 30 นาที</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios6"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice6']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">7) สามารถก้มหรือเอี้ยวตัวได้เต็มที่</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios7"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice7']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >8) สามารถนั่งยองๆ
        หรือมีปัญหาขณะลุกขึ้นยืนจากท่านั่งยอง</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios8"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice8']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >9) สามารถนั่งบนพื้นได้นาน เช่น นั่งคุกเข่า นั่งขัดสมาธิ
        นั่งพับเพียบหรือมีปัญหาขณะลุกขึ้นยืนจาก ท่านั่งบนพื้น</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios9"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice9']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >10) สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios10"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice10']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >11) สามารถยกของขึ้น-ลง ผลักหรือดึงของ ซ้ำๆบ่อยๆ ได้
      </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios11"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice11']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >12) มีปัญหาในการหยิบจับ วัตถุขนาดเล็ก เช่น เหรียญ</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios12"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice12']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >13) สามารถคิด หรือตัดสินใจ ในปัญหาที่เกิดขึ้นได้ทันที</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios13"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice13']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">14) มีปัญหาการได้ยินเสียงไม่ชัดเจน</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios14"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice14']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >15) มีอาการชา ซู่ซ่าเหมือนเป็นเหน็บ หรือแปลบปลาบคล้ายเข็มตำ
        ที่บริเวณใดบริเวณหนึ่งของแขน ขา มือ หรือ เท้า</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios15"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice15']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">16) มีปัญหาผิวหนังแพ้ง่าย</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in radios16"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="selects['choice16']"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => setModal(false)"
      />
      <Button
        label="ยืนยันผล"
        icon="pi pi-check"
        autofocus
        @click="setLabelValueAndCloseModal"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalHealthSum',
  data() {
    return {
      selects: {
        choice1: null,
        choice2: null,
        choice3: null,
        choice4: null,
        choice5: null,
        choice6: null,
        choice7: null,
        choice8: null,
        choice9: null,
        choice10: null,
        choice11: null,
        choice12: null,
        choice13: null,
        choice14: null,
        choice15: null,
        choice16: null
      },
      radios1: ['ไม่มีอาการ', 'มีอาการ'],
      radios2: ['ไม่มีอาการ', 'มีอาการ'],
      radios3: ['ไม่มีปัญหา', 'มีปัญหา'],
      radios4: ['ไม่มีปัญหา', 'มีปัญหา'],
      radios5: ['สามารถ', 'ไม่สามารถ'],
      radios6: ['สามารถ', 'ไม่สามารถ'],
      radios7: ['สามารถ', 'ไม่สามารถ'],
      radios8: ['สามารถ', 'ไม่สามารถ'],
      radios9: ['สามารถ', 'ไม่สามารถ'],
      radios10: ['สามารถ', 'ไม่สามารถ'],
      radios11: ['สามารถ', 'ไม่สามารถ'],
      radios12: ['ไม่มีปัญหา', 'มีปัญหา'],
      radios13: ['สามารถ', 'ไม่สามารถ'],
      radios14: ['ไม่มีปัญหา', 'มีปัญหา'],
      radios15: ['ไม่มีอาการ', 'มีอาการ'],
      radios16: ['ไม่มีปัญหา', 'มีปัญหา']
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters['prediction/isModalHealthSum']
    }
  },
  methods: {
    setModal(isOpen) {
      this.$store.commit('prediction/setModal', {
        modalName: 'health_sum',
        isOpen
      })
    },
    setLabelValueAndCloseModal() {
      const value = Object.keys(this.selects).reduce((sum, name) => {
        const value = this.selects[name]
        return sum + (value == null ? 0 : value)
      }, 0)
      console.log('value ::==', value)
      this.$store.commit('prediction/setLabelValue', {
        labelName: 'health_sum',
        value
      })
      this.setModal(false)
    }
  }
}
</script>

<style>
</style>